@font-face {
  font-family: 'Swiss721BT';
  src: local('Swiss721BT'), url('asset/font/Swiss721BTBold.woff2') format('woff2'),
       url('asset/font/Swiss721BTBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Swiss721BT';
  src: local('Swiss721BT'), url('asset/font/Swiss721BTMedium.woff2') format('woff2'),
       url('asset/font/Swiss721BTMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Swiss721BT';
  src: local('Swiss721BT'), url('asset/font/Swiss721BTRegular.woff2') format('woff2'),
       url('asset/font/Swiss721BTRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body {
  margin: 0;
  font-family: 'Swiss721BT', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
  width: 100%;
  letter-spacing: -.0412em;
  font-size: 16px;
  background: #1A1B1C;
}

#root {
  height: 100%;
}

div {
  box-sizing: border-box; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip-divider {
  border-bottom: 1px solid #fafafa;
  width: 30px;
  height: 1px;
  margin: 12px 0;
}

.tooltip-divider-dark {
  border-bottom: 1px solid #333;
  width: 30px;
  height: 1px;
  margin: 12px 0;
}